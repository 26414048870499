<template>
  <div class="OrderSubmitBox" >
    <el-row class="OrderSubmitTop">
      <div class="OrderSubmitTopLeft">
        <i class="el-icon-circle-check"></i>
      </div>

      <div class="OrderSubmitTopCenter">
        <p>订单提交成功！请付款</p>
        <p>成功付款后，3天发货</p>
        <p>
          请在
          <CountDown v-if="isCountDowmFlag" :countTime="countTime"></CountDown>
          <span class="CountDown" else>
            <strong>{{CountDowmTime}}</strong>
          </span>
          内完成支付，超时后取消订单
        </p>
      </div>
      <div class="OrderSubmitTopRight">
        <p>
          <span>支付金额：</span><span>￥{{ total }}</span>
        </p>
      </div>
    </el-row>
    <p class="OrderSubmitTit" v-if="payFlag">选择支付方式</p>
    <el-row class="OrderSubmitBottom" v-if="payFlag">
      <div class="OrderSubmitBottomTop">
        <div
          :class="{ active: Boolean(activeFlag === 1) }"
          @click="payBtnFun(1)"
        >
          <img src="@/assets/wechatIcon.png" alt="" />
        </div>
        <div
          :class="{ active: Boolean(activeFlag === 2) }"
          @click="payBtnFun(2)"
        >
         <img src="@/assets/alipayIcon.png" alt="" />
        </div>
        <div
          :class="{ active: Boolean(activeFlag === 4) }"
          @click="payBtnFun(3)"
        >
          <img src="@/assets/prepaidIcon.png" alt="" />
        </div>

        <div
          v-if="creditShow"
          :class="{ active: Boolean(activeFlag === 5) }"
          @click="payBtnFun(4)"
        >
          <img src="@/assets/shouxing.png" alt="" />
        </div>
      </div>
      <div class="OrderSubmitBottomBottom">
        <span @click="payOrder">立即支付</span>
      </div>
    </el-row>
    <!-- 支付二维码弹框 -->
    <el-dialog
      title="扫码支付"
      center
      :visible.sync="DialogShowQRCode"
      width="300px"
      height="300px"
      @close="closeDialog"
      id="qrBox"
    >
      <vue-qr
        :bgSrc="config.logo"
        :logoSrc="config.logo"
        :text="config.value"
        :size="200"
      ></vue-qr>
      <!-- <p>扫码支付后,请点击关闭二维码</p> -->
    </el-dialog>
    <el-dialog
      id="paypasBox"
      :title="titleMSG"
      :visible.sync="dialogFormVisible"
      width="400px"
      height="200px"
    >
      <p>为了保证您的账户安全，请输入{{titleMSG}}</p>
      <el-form :model="form">
        <el-form-item label="支付密码：">
          <el-input v-model="form.money" 
          type="password"
            onkeyup="value=value.replace(/[^\d]/g,'')"
              :maxlength="6"
                 ref="money"
          autocomplete="off" placeholder="6位数字"></el-input><span class="pasmsg" @click="jumpRepaypass">修改密码？</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="offbtn" @click="dialogFormVisible = false">取消支付</el-button>
        <el-button class="surebtn" @click="submitPay"
          >确认付款</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCurrentMemberStoreCreditStatus,getCurrentMemberStoreAvailableQuota} from "api/creditPay.js";
const CountDown = () => import("components/order/CountDown.vue");
import moment from "moment";
import VueQr from "vue-qr";
import { OrderDetail } from "api/order.js";
import { payOrder } from "api/carToOrder.js";
import Qs from "qs";
import { MemberInfo } from "api/member.js";
export default {
  name: "OrderSumbit",
  data() {
    return {
      creditShow:false,
      titleMSG:'',
      form: {
        money: "",
      },
      dialogFormVisible: false, //支付密码弹框
      DialogShowQRCode: false,
      activeFlag: 1, //默认支付方式
      total: 0,
      creatTime: 0, //订单创建时间
      config: {
        value: "", //显示的值、跳转的地址
        imagePath: require("../assets/logo.png"), //中间logo的地址
      },
      countTime: {
        minute: 0,
        second: 0,
      },
      CountDowmTime:'',//倒计时日期
      payFlag: true,
      isPayPas: 0, //是否设置支付密码 1设置，0未设置,
      isCountDowmFlag:false,
    };
  },
  methods: {
    jumpRepaypass(){
        this.$router.push({ name: "Repaypass" });
    },
    // 余额支付
    submitPay(){
      if(this.form.money==''){
        this.$message({
             message:'请输入支付密码',
             type:"warning"
         })
      }else{
        let datas = {
          buyChannel: 1,
          paymentMethod: this.activeFlag,
          id: sessionStorage.getItem("orderId"),
          payPassword: this.$md5(this.form.money) 
        };
        payOrder(datas).then((data) => {
          if (data.data.code == 200) {
            this.$message({
                  message:data.data.msg,
                  type: 'success'
                });
            this.dialogFormVisible = false
              // this.$router.push({name:'MyOrder'});
               let datas = {
                  sn: sessionStorage.getItem("orderId"),
                  total: this.total,
                  creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                };
                sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                // 跳转支付成功页面
                this.$router.push({ path: "/paysuccess", query: { datas: datas } });
          }else{
             this.$message.error(data.data.msg);
             this.form.money='';
                this.$nextTick(() => {
                  this.$refs.money.focus()
                },100)
                return
          }
        });
      }
    },
    // 选择支付方式
    payBtnFun(index) {
      if (index == 1) {
        if (this.activeFlag == 1) {
          this.activeFlag = 0;
        } else {
          this.activeFlag = 1;
        }
      } else if (index == 2) {
        if (this.activeFlag == 2) {
          this.activeFlag = 0;
        } else {
          this.activeFlag = 2;
        }
      } else if (index == 3) {
        if (this.activeFlag == 4) {
          this.activeFlag = 0;
        } else {
          this.activeFlag = 4;
        }
      }else if (index == 4) {
        if (this.activeFlag == 5) {
          this.activeFlag = 0;
        } else {
          this.activeFlag = 5;
        }
      }
    },

    payOrder() {
      if(this.activeFlag <1){
         this.$message({
              message: "请先勾选支付方式",
              type: "warning",
            });
            return false
      }
      let datas = {
        buyChannel: 1,
        paymentMethod: this.activeFlag,
        id: sessionStorage.getItem("orderId"),
      };
      if (this.activeFlag != 4 && this.activeFlag != 5) {
        //订单支付接口   出价id，buyChannel，付款方式
        payOrder(datas).then((data) => {
          if (data.data.code == 200) {
            //获取支付链接  生成二维码
            this.DialogShowQRCode = true;
            this.config.value = data.data.data.billQRCode;
          }
        });
      } else {

        if(this.activeFlag === 4){
          this.titleMSG="预存款支付密码"
        }
        else if(this.activeFlag === 5){
          this.titleMSG="授信支付密码"
        }
         // 跳转余额支付页面
              // 先判断有无设置支付密码
              // 获取店铺信息
              MemberInfo().then((res) => {
                this.isPayPas = res.data.data.isSetPayPassword;
                if (this.isPayPas === 0) {
                  // 未设置支付密码
                  this.$confirm(
                    "请根据您的情况点击下面的按钮",
                    "请确认是否跳转设置预存款支付密码",
                    {
                      confirmButtonText: "是",
                      cancelButtonText: "否",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      // 跳转设置支付密码
                      this.$router.push({ name: "Paypass",query:{typeUp:"Order"} });
                    })
                    .catch(() => {
                      // 不跳转关闭弹框
                    });
                } else {
                  //有支付密码，直接支付
                  this.dialogFormVisible=true
                }
              });
      }
    },

    closeDialog() {
      this.$confirm("请根据您的支付情况点击下面的按钮", "请确认支付是否完成", {
        confirmButtonText: "已完成支付",
        cancelButtonText: "支付遇到问题",
        type: "warning",
      })
        .then(() => {
          // 跳转支付成功页面
          // 去判断是否支付成功
         this.OrderDetailFun().then((data)=>{
           if(data.data.data.status==2){//订单为待发货状态
                let datas = {
                  sn: sessionStorage.getItem("orderId"),
                  total: this.total,
                  creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                };
                sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                // 跳转支付成功页面
                this.$router.push({ path: "/paysuccess", query: { datas: datas } });

           }else{
              this.$message({
                message:'您未支付成功该订单',
                type: "warning",
              });
           }
         })
        })
        .catch(() => {
          // 支付不成功，关闭当前弹框，停留当前页面
          // this.$router.push({ path: "/home" });

              // 去判断是否支付成功
         this.OrderDetailFun().then((data)=>{
           if(data.data.data.status==2){//订单为待发货状态
                let datas = {
                  sn: sessionStorage.getItem("orderId"),
                  total: this.total,
                  creatTime: moment(this.creatTime).format("YYYY-MM-DD a hh:mm:ss"),
                };
                sessionStorage.setItem("orderDatas", Qs.stringify(datas));
                // 跳转支付成功页面
                this.$router.push({ path: "/paysuccess", query: { datas: datas } });

           }else{
              this.$message({
                message:'您未支付成功该订单',
                type: "warning",
              });
           }
         })

        });
    },
    // 通过订单id ，获取订单详情接口
    async OrderDetailFun() {
      let data = await OrderDetail(sessionStorage.getItem("orderId"));
      return data;
    },
// 时间戳转日期
     formatDate (value) {
        let date = new Date(value)
        let y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        let s = date.getSeconds()
        s = s < 10 ? ('0' + s) : s
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
        // return y + '-' + MM + '-' + d
      }
  },

  created() {
    var lett = this;
    document.onkeydown = function() {
        var key = window.event.keyCode;
        if (key == 13) {
         lett.submitPay()
        }
    }
    
    // 订单结算信息
    this.OrderDetailFun().then((data) => {
      const creatTime = new Date(data.data.data.createTime).getTime();
      this.total = data.data.data.payableAmount;

      //  获取当前门店的授信状态   status:{  1:正常    3:冻结      -1 未开通   0:审核失败   2:审核中 }
      getCurrentMemberStoreCreditStatus().then(res=>{
        if(res.data.data.status===1){
           // 获取当前门店的可用额度
            getCurrentMemberStoreAvailableQuota().then(res=>{
                console.log('当前门店的可用额度',res.data.data)
                console.log(this.total)
                if(Number(this.total)>Number(res.data.data)){
                  this.creditShow=false
                }else{
                    this.creditShow=true
                }
            })
        }else{
           this.creditShow=false
        }
      }),
      // 创建订单时间
      this.creatTime = creatTime;
      // 订单支付终止时间

      const endTime = this.creatTime + data.data.data.overTime;
     

      this.countTime.minute = data.data.data.overTime / 1000 / 60; //初始倒计时分钟数
      if(this.countTime.minute>120){
        this.isCountDowmFlag=false;
         this.CountDowmTime=this.formatDate(endTime)
      }else{
        this.isCountDowmFlag=true
      }
      this.countTime.second = 0; //初始倒计时秒数

      let diffTime = Number(endTime) - Number(Date.now());
      if (diffTime > 0) {
        //当前时间  小于  支付终止时间 倒计时
        this.countTime.minute = parseInt(diffTime / 1000 / 60);
        const second = parseInt(
          ((diffTime / 1000 / 60).toFixed(2) - parseInt(diffTime / 1000 / 60)) *
            60
        );
        this.countTime.second = second;
      } else {
        this.countTime.minute = 0;
        this.countTime.second = 0;
        this.payFlag = false; //超过时间  隐藏支付按钮
        this.$message({
          message: "已超过支付时间",
          type: "warning",
        });
      }
    });
  },
  wacth: {},
  mounted() {
   
  },
  updated() {
  },
  components: {
    CountDown,
    VueQr,
  },
};
</script>
<style lang="less" scoped>
.CountDown {
  color: #FFD303;
}
/deep/#paypasBox {
  .el-dialog {
    p {
      color: #999999;
      font-size: 13px;
    }
    .el-dialog__header {
      padding: 10px 20px 10px;
      background: #FFD303;
      .el-dialog__title {
        color: #000;
      }
    }
    .el-dialog__headerbtn {
      top: 15px;
      .el-icon-close {
        color: #000;
      }
    }
    .el-dialog__body {
      padding: 30px 20px 0px 20px;
      .el-form-item {
        margin-top: 20px;
        .el-form-item__content {
          float: left;
          .el-input{
            width: 190px;
          }
          .pasmsg{
            color: #2986FF;
            padding-left: 10px;
          }
        }
      }
      
    }
    .surebtn{
          background-color: #FFD303;
    border-color: #FFD303;
    color: #000;
    }
    .offbtn{
       border-color: #000;
       color: #A3A3A3;
    }
  }
}
.OrderSubmitBox {
  padding: 20px 0;
  /deep/#qrBox {
    .el-dialog__body {
      padding: 0px 25px 10px !important;
      img {
        display: block !important;
        margin: 0 auto;
      }
    }
  }

  .OrderSubmitTop {
    padding: 20px 40px;
    height: 120px;
    background: #f6f6f6;
    margin-bottom: 20px;
    .OrderSubmitTopLeft {
      padding-right: 20px;
      width: 80px;
      float: left;

      i {
        font-size: 74px;
        color: #fa8c16;
        line-height: 80px;
        text-align: center;
      }
    }
    .OrderSubmitTopCenter {
      float: left;
      p:nth-child(1) {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        line-height: 28px;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-weight: bold;
        color: #333;
        line-height: 22px;
      }
      p:nth-child(3) {
        font-size: 12px;
        font-weight: bold;
        color: #333;
        line-height: 22px;
        span {
          b {
            color: #FFD303;
          }
        }
      }
    }
    .OrderSubmitTopRight {
      float: right;
      p {
        span:nth-child(1) {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
        }
        span:nth-child(2) {
          color: #FFD303;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .OrderSubmitTit {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 60px;
  }
  .OrderSubmitBottom {
    padding: 20px 40px;
    border: 1px solid #cccccc;
    .OrderSubmitBottomTop {
      div {
        padding: 11px 22px;
        width: 106px;
        height: 28px;
        display: inline-block;
        border: 2px solid #f0f0f0;
        margin-right: 50px;
        text-align: center;
        line-height: 28px;
        img {
          width: 100%;
          height: 100%;
        }
        cursor: pointer;
        &:hover{
           border: 2px solid #FFD303;
          background: url("../assets/checkedRed.png") no-repeat right bottom;
          background-size: 26px 26px;
        }
        &.active {
          border: 2px solid #FFD303;
          background: url("../assets/checkedRed.png") no-repeat right bottom;
          background-size: 26px 26px;
        }
      }
      div:nth-child(1) {
        img {
          width: 90%;
        }
      }
      div:nth-child(2) {
        img {
          width: 86%;
        }
      }
      div:nth-child(3) {
        img {
          width: 100%;
        }
      }
    }
    .OrderSubmitBottomBottom {
      margin-top: 14px;
      span {
        width: 154px;
        display: block;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #FFD303;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
